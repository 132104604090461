@import "../base";

  
.devices-map{
        
    z-index: 1;
    position: relative;

   
    .leaflet-container {        
        width: 100vw; 
        height: 82vh;

        .leaflet-control-container .leaflet-top.leaflet-left {
            z-index: 999;
        }
    }

    .leaflet-popup-content-wrapper {

        .data {

            .title , .ant-btn {
                font-weight: bold;
                font-family: "Roboto Condensed";
                font-size: 16px;
                margin: 0px;
                padding: 0px;

                &.error {
                    color:red;
                }
            }

            .name {
                font-style: italic;
                margin: 0px;
            }

            .area {
                margin: 0px;
            }
                                
            .status {
                font-family: "Roboto Condensed";
                font-size: 13px;
                margin-bottom: 0px;
            }

            .params {
                width: 100%;
                border: 0;
                border-top: 1px solid #999999;
                border-bottom: 1px solid #999999;
                margin-top: 15px;
                margin-bottom: 15px;
                padding-top: 5px;
                padding-bottom: 5px;

                p {
                    line-height: normal;
                    padding: 0px;
                    margin: 0px;
                }
            }
              
        }
    }
}
