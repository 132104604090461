@import "../base";

section.userprofile-layout {
    background-color: $main-area-color;
    min-height: 100%;

    main { 
        padding-top: 100px;
        padding-bottom: $footer-collapsed-height;

        @include sm {
            padding-top: $header-height;
        } 

        .user-profile-data{
            text-align: justify;

            h2 {
                margin-top: 18px;
                font-family: Lato;
                color: $secondary-area-color; 
                font-size: 1.5em;

                @include sm {
                    font-size: 2em;
                }     
            }                

            .text{
                padding: 0px 10px 0px 10px;     
                text-align: center;
            }

            .ant-row{
                justify-content: center;
            }

            .ant-form-item{
                margin: 0px;
                padding: 10px;
            }

            .ant-form-item-label{
                font-weight: bold;
                padding: 0px;
            }

            .ant-input {
                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active  {
                    //Para tener un fondo transparente en el autofill
                    //https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
                    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
                            transition: "color 9999s ease-out, background-color 9999s ease-out";
                    -webkit-transition-delay: 9999s;
                            transition-delay: 9999s;
                }               

                &.ant-input-disabled {
                    color: #0000008c;
                }
            }
            
        }   
    }    

    footer {
        border-top: 2px solid $main-area-color;
        background-color: $secondary-area-color;
        position: fixed;
        bottom: 0px;
        z-index: 10;
        width: 100%;
        padding: 0;
        min-height: $footer-collapsed-height;
        text-align: center;
        padding: 5px;

        .ant-form-item {
            display: inline-block;
            margin: 0px;

            .user-profile-form-button {
                margin-right: 20px;
            }
        }
    }
}