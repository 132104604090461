@import "../base";

section.control-panel-layout {

    background:$main-area-color;
    min-height: 100%;
    background-size: 200% 100%;
        
    main {
        padding-bottom: $footer-collapsed-height;
    }

    footer {
        border-top: 2px solid $main-area-color;
        background-color: $secondary-area-color;
        position: fixed;
        bottom: 0px;
        z-index: 1000;
        width: 100%;
        padding: 0;
        min-height: $footer-collapsed-height;        
        
        .reloading {
            position: absolute;
            z-index: 100;
            margin: 0px 10px;
            top: 3px;
            right: 0px;
        }

        .filter {
            background-color: transparent;
            border: 0;

            .ant-collapse-header {
                padding: 8px 16px;
                color: white;
                font-family: "Montserrat";

                &:hover {
                    background-color: lighten($secondary-area-color, 10%);
                }

                .ant-collapse-arrow {
                    top: 0px;
                }
            }

            .ant-collapse-content-box {
                background-color: lighten($secondary-area-color, 10%);
                padding: 3px;
            }
        }


   }
}