@import "../base";

section.data-loader-panel-layout {

    background:$main-area-color;
    min-height: 100%;
    background-size: 200% 100%;
        
    main {
        padding-bottom: $footer-collapsed-height;
    }

    footer {
        border-top: 2px solid $main-area-color;
        background-color: $secondary-area-color;
        position: fixed;
        bottom: 0px;
        z-index: 1000;
        width: 100%;
        padding: 0;
        min-height: $footer-collapsed-height;        
   }
}