@import "../base";

section.firstrun-layout {
    background-image: url(../assets/images/login-background.png);
    background-position: left;
    background-size: cover;
    background-color: $login-bg-color;
    background-blend-mode: inherit;
    height: 100%;    

    .ant-form-item-explain-error {
        color:yellow;
    }
    
    .ant-btn-primary {
        width: 100%;
        margin-top: 10px;
        font-size: 1.2em;
        height: 100%; 
        border-radius: 20px;
        
                
        @include md {
            font-size: 1.5em;
        }     
    }
      
    .ant-input {
        background-color: $login-fields-bg-color;
        color: $login-fields-color;
        padding: 0px 10px;
        font-size: 1.1em;
        margin: 5px;

        @include md {
            font-size: 1.5em;
        }
            
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            box-shadow: inset 0 0 0px $login-fields-bg-color;
            -webkit-box-shadow: 0 0 0px 1000px $login-fields-bg-color inset;
            -webkit-text-fill-color: $login-fields-color;
        }                
    }

    .ant-input-affix-wrapper {
        background-color: transparent;
        border: none;
        padding: 0.5em;
    }    

    main { 

        margin: 0;
    
        top: 0px;
        width: 100%;
    
        @include md {
            padding: 2em;
        }
    
        .logoApp {
            width: 40vh;
            padding: 1em;
        }

        .logoCompany {
            width: 20vh;
            padding: 1em;
            display: none;

            @include md {
                display: inline-block;
            }              
        }

        h2.app-subtitle {
            font-family: "Lato", sans-serif;
            color: white;
            font-weight: lighter;
            display: inline-block;
            font-size: 1.4em;
            margin-top: 1em;
            display: block;
    
            @include md {
                padding-bottom: 1em;
            }        
        }

        .bottom-link {
            color: white;
            font-size: 16px;
            margin-top: 10px;

            &:hover {
                color: $login-fields-bg-color;
            }
        }

        p.app-hello {
            color: white;
            display: inline-block;
            margin-bottom: 0;
            width: 100%;
            text-align: center;
        }

        .firstrun-form
        {
            background-color: $login-window-bg-color;
            border-radius: 20px;
            padding: 10px;

            .ant-form-item {
                margin: 0;
            }

            .form-field {
                margin: 0;

                .ant-input-prefix {
                    width:40%;
                    color: $login-fields-color;
                    font-size: 1.2em;

                    @include md {  
                        font-size: 1.5em; 
                    }
                }                
            }

            .ant-form-item-explain {
                padding: 0px;
                margin-top: -1em;
                color: white;
                font-size: 1em;
                text-align: center;
            }
        }

    }

}
  