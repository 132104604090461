@import "../base";


    .header {
        
        background-image: url(../assets/images/main-header-background.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: bottom;
        height: 118px;
        background-color: transparent;
        padding: 10px 10px;
        width: 100%;
        z-index: 100;        

        @include sm {
            padding: 10px 20px;
            height:  $header-height;
        }        

        .menu-content {
            text-align: right;
        }

        .logo-app {
            width: 200px;

            @include xs {
                width: 150px;
            }
        
        }

        .logo-demeter {
            width: 200px;

            @include xs {
                width: 150px;
            }
        
        }

        .ant-menu {
           background-color: transparent;
           background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2));
           line-height: 40px;
           height: 40px;
           margin-left: 150px;        
           border: 0;
           color: white;
           font-size: 16px;
           display: block;       
           text-align: right; 
           margin-right: -7px;
    
           @include sm {
            margin-right: -17px !important;
           }

           span.anticon {
                font-size: 22px;

                @include md {
                    display: inline;
                    position: absolute;
                    left: 3px;
                    transform: translate(0%, 40%);
                }

           }

           .ant-menu-submenu {
                border-bottom: 0;

                &:hover {
                    color: white;
                    border-bottom: 0;
                }

                &:hover::after {
                    content: none;
                }                
            }

            .ant-menu-submenu:after {
                border-bottom: none !important;
            }

            .ant-menu-submenu-title {
                border-bottom: 0;
                text-align: center;
                color: white;
                margin-right: -20px;

                transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                &:active {
                    color: $link-hover-color;
                }

                span {
                    display: none;    
                }

                &::after {
                    font-family: "Font Awesome 5 Free";
                    content: "\f13a";
                    font-weight:900;
                    font-size: 25px;
                    padding: 0px 5px;
                    position: relative;
                    top: 2px;
                }

                &:hover {
                    color: $link-hover-color;                    
                }

            }

            .ant-menu-item {

                border-bottom: none !important;
                transition: none !important;
                margin-top: -4px;

                &:hover {
                    color: $link-hover-color;
                    border-bottom: 0;
                }

                &::after {
                    content: none;
                }

                &:hover::after {
                    content: none;
                }

                &.ant-menu-item-selected {
                    color: white;
                }
            }
        }

    }
 
