@import "../base";

  
.single-data-loader{
        
    padding-top: 30px;
    background-color: $main-area-color;
    min-height: 100%;
    
    .text{
        padding: 0px 10px 0px 10px;     
        text-align: center;
    }

    .ant-row{
        justify-content: center;
    }

    .ant-form-item{
        margin: 0px;
        padding: 10px;

        .ant-form-item-label{
            font-weight: bold;
            padding: 0px;
        }

        &.required .ant-form-item-label::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }

    .ant-input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            //Para tener un fondo transparente en el autofill
            //https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
            -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
                    transition: "color 9999s ease-out, background-color 9999s ease-out";
            -webkit-transition-delay: 9999s;
                    transition-delay: 9999s;
        }               

        &.ant-input-disabled {
            color: #0000008c;
        }
    }
    
 }

 .single-data-loader-modal-loading {

    .modal-loading-content {

        text-align: center;

        .icon{
            font-size: 50px;
            margin-bottom: 10px;
        }

        .text {
            font-size: 20px;
        }

        .loading {
            padding: 10px;
        }        
    }
}