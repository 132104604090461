@import "../base";

.users-management-filter {

    .text-option {
        .ant-input-search {
            width: 70%;
            float: right;

            @include md {
                float: none;
                width: 100%;
            }     
        }    
    }
    
    span.field {
        display: inline-block;
        text-align: left;

        @include md {
            display: block;
        }     
    }

    .role-option {

        .ant-select {
            float: right;
            width: 220px;

            @include md {
                float: none;
                width: 100%;
            }         
        }
        
    }

    .ant-switch {
        float: right;

        @include md {
            float: none;
        }     
    }      

    .delete-option {
        text-align: center;   
    }    

    .ant-col {
        padding: 3px;

        @include md {
            padding: 6px;
        }
    }
}