@import "../base";

.sensors-filter {

    .text-option {
        .ant-input-search {
            width: 45%;
            float: right;

            @include md {
                float: none;
                width: 100%;
            }     
        }    
    }
    
    span.field {
        display: inline-block;
        text-align: left;

        @include md {
            display: block;
        }     
    }

    .select-option {

        .ant-select {
            float: right;
            width: 200px;

            @include md {
                float: none;
                width: 100%;
            }         
        }
        
    }

    .date-option {

        .ant-picker {

            float: right;

            @include md {
                float: none;
            }         
        }
        
    }
        
    .user-capture-option .ant-select {
        width: 100%;
    }

    .delete-option {
        text-align: center;   
    }    

    .ant-col {
        padding: 3px;

        @include md {
            padding: 6px;
        }
    }
}