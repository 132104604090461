@import "../base";

.devices {
    
    .loading-more {
        width: 100%;
        text-align: center;
        padding: 20px 0px;
    }

    .list-screen-mode-header {
        font-weight: bold;
        font-family: "Roboto Condensed";
        font-size: 16px;
        margin: 0px;
        padding: 3px;
        margin: 0px 5px;
        background-color: $main-area-color;
        
        display: none;

        @include md {
            display: flex;
        }
    }
    
    li.ant-list-item {
        
        padding: 0px;
        border-bottom: 1px solid $secondary-area-color;

        @include md {
            border: 0;
            padding: 0px;
            width: 50%;
            float: left;
            border: none;

            &:hover {
                box-shadow: unset;
                -webkit-box-shadow: unset;
            }

            .list-item {
                border-radius: 10px;
                margin: 10px;
            }
        }        

        @include xl {
            width: 33%;
        }

        .list-item {
            &.disabled {
                background-color: red !important;
            }

            &:hover {
                box-shadow: 0 0 0px 1000px $link-hover-color;
                -webkit-box-shadow: 0 0 0px 1000px $link-hover-color inset;
                cursor: pointer;
            }
        }

        .list-item.list-screen-mode {
            display:none;
        }

        .list-item.box-screen-mode {
            width: 100%;
            padding: 0px 10px;
            background-color: $data-box-color;

            .data {
                padding-right: 0px;
                text-align: justify;
                margin-top: 0.5em;
                width: 100%;

                @include md {
                    margin-top: 1em;
                }

                .text-data {

                    @include md {
                        max-height: 158px;
                        overflow: hidden;
                        display: grid;
                    }         
        
                    .title {
                        font-weight: bold;
                        font-family: "Roboto Condensed";
                        font-size: 16px;
                        margin: 0px;

                        &.error {
                            color:red;
                        }
                    }

                    .name {
                        font-style: italic;
                        margin: 0px;
                    }

                    .area {
                        margin: 0px;
                    }
                                        
                    .status {
                        font-family: "Roboto Condensed";
                        font-size: 13px;
                        margin-bottom: 0px;
                    }
    
                }

                .dates {
                    bottom: 0px;
                    font-family: "Roboto Condensed";
                    color: #777777;
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;
                    
                    @include md {
                        margin-top: 1em;
                        margin-top: 1em;
                    }
                    
                    .align-right {
                        text-align: left;
                        padding-right: 5px;

                        @include md {
                            text-align: right;
                        }     
                    }
                }
                
                .params {
                    width: 100%;
                    background-color: rgba(255,255,255,0.3);
                    border: 0;
                    height: 100px;
                    overflow-y: auto;                    
                    padding: 3px;

                    p {
                        line-height: normal;
                        padding: 0px;
                        margin: 0px;
                    }
                }

            }

            .options {
                text-align: right;

                button {
                    margin: 0px 0px 5px 0px;
                }
            }          
        }

        &.as-list {

            @include md {
                width: 100%;

                .list-item.list-screen-mode {
                    display: flex;
                    width: 100%;
                    background-color: $data-box-color;
                    padding: 3px 5px;
                    margin: 2px 5px;
                }

                .list-item.box-screen-mode {
                    display:none;
                }

                .options {
                    text-align: right;
                }
            }

            Button {
                padding: 0;
                height: auto;

                &.actionButton .anticon {
                    font-size: 18px;
                    color: $main-button-bg-color;
                }
            }
        }
    }
}

