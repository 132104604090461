@import "../base";

.modal-with-map {

    .ant-modal-content {

        @include md {
            width: 50vw; 
        }                       
    }

    .leaflet-container {        
        width: 100%; 
        height: 60vh;

        .leaflet-control-container .leaflet-top.leaflet-left {
            z-index: 999;
        }
    }

}
