@import "../base";

  
.sensors-stats{
        
    padding-top: 10px;
    background-color: $main-area-color;
    min-height: 100%;

    h2 {
        text-align: center;
    }

    .pie-panel-content {
        margin: 0px 8px 10px 8px;

        h1{
            font-weight: bold;
            text-align: center;
            margin: 0;
        }
        

        .review-info-panel-data{
            width: 100%;
            text-align: center;
            background-color: #e8e8e8;
            border-radius: 20px;

            
            .review-info-panel-mid{
                margin-top: -50px;
            }
        }

    }

    .areas-panel-content {
        margin: 0px 8px 10px 8px;

        h1{
            font-weight: bold;
            text-align: center;
            margin: 0;
        }
        

        .review-info-panel-data{
            width: 100%;
            text-align: center;
            background-color: #e8e8e8;
            border-radius: 20px;
            padding: 20px;            
        }

    }    

 }
