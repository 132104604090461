
// Font Variables --------------------------------------------------------------------------------------------
$app-title-font: 'Raleway', sans-serif;
$titles-font: 'Montserrat', sans-serif;
$subtitles-font: 'Roboto', sans-serif;
$main-font: 'Lato', sans-serif;

$main-area-color: #fcfcfc;
$secondary-area-color: #85b044;
$login-window-bg-color:#85b044cc;
$login-window-icons-color:  #ffffff;
$login-bg-color:#888888d9;
$login-fields-bg-color: #f2f6cd;
$login-fields-color: #606060;
$link-hover-color: #dbdb1a;
$data-box-color: #f9d5bb;
$local-menu-color-bg-color: #e78e18;

$main-button-bg-color: rgb(108,138,93);

// Areas sizes -----------------------------------------------------------------------------------------------

$header-height: 118px;
$footer-collapsed-height: 40px;

// Breakpoints -----------------------------------------------------------------------------------------------

// Very small devices
$screen-xs-min: 320px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Big screens
$screen-xxl-min: 1600px;

// Very small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
      @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

// Big screens
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
      @content;
  }
}

// Remove outline border in input fields where they are focused
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:hover {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: transparent !important;
}

.ant-switch {
  background: $main-button-bg-color;
  border-color: darken($main-button-bg-color, 1%);

  &.ant-switch-checked {
    background: lighten($main-button-bg-color, 20%);
  }
}

.ant-btn-primary {
  background: $main-button-bg-color;
  border-color: darken($main-button-bg-color, 1%);

  &:hover {
    background: lighten($main-button-bg-color, 20%);
    border-color: lighten($main-button-bg-color, 15%);
  }

  &:focus {
    background: lighten($main-button-bg-color, 20%);
    border-color: lighten($main-button-bg-color, 15%);
  }
}

.hidden-element {
  display: none;
}

.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: inherit !important;
}

.tab-menu {

  .ant-tabs-ink-bar {
      border-bottom: 2px solid darken($secondary-area-color, 10%);
      background: darken($secondary-area-color, 10%);
      margin-top: -2px;
  }

  .ant-tabs-nav {
      border-bottom: 0px;
      background-color: $main-area-color;
      z-index: 1000;
      margin-bottom: 0px;

      &::before {
          border-bottom: 2px solid lighten($secondary-area-color, 20%);
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          content: "";
      }

      .ant-tabs-tab {
          font-size: 1em;
          padding: 0px 20px;
          margin: 0px;
          color: $secondary-area-color;

          @include sm {
              font-size: 2em;
          }          
          
      }

      .ant-tabs-nav-wrap {
          justify-content: center;
          position: relative;
          display: flex;
          flex: auto;
          align-self: stretch;
          white-space: nowrap;
          transform: translate(0);
      }

      .ant-tabs-tab-active {
          font-size: 1.5em;                
          
          @include sm {
              font-size: 2.5em;
          }                 

          .ant-tabs-tab-btn {
              color: red;
          }
      }
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
      color: $secondary-area-color;
  }

  .ant-tabs-tabpane {
      margin: 0px;
  }

  .ant-tabs-nav-operations-hidden {
    display: none;
  }
}

.ant-modal-body {
  padding: 0px; 

  @include md {
    padding: 20px; 
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}