@import "../base";

  
.detection-demo{
        
    padding-top: 30px;
    background-color: $main-area-color;
    min-height: 100%;
    
    .text{
        padding: 0px 10px 0px 10px;     
        text-align: center;
    }

    .ant-row{
        justify-content: center;
    }

    .ant-form-item{
        margin: 0px;
        padding: 10px;

        .ant-form-item-label{
            font-weight: bold;
            padding: 0px;
        }

        .ant-upload-list {
            text-align: center;
        }

        .ant-form-item-explain {
            text-align: center;
        }
    }

 }

 .detection-demo-data-loader-modal-loading {

    .modal-loading-content {

        text-align: center;

        .icon{
            font-size: 50px;
            margin-bottom: 10px;
        }

        .text {
            font-size: 20px;
        }

        .loading {
            padding: 10px;
        }
    }
}